import ContactUsBlock from "../components/ContactUsBlock";
import {Helmet} from "react-helmet";

const Contacts  = () => {
  return (
    <div>
      <Helmet>
        <title>Սարգիս Բաբայան - Հայ քանդակագործ | Պաշտոնական կայք</title>
        <meta
          name="contacts"
          content="Սարգիս Բաբայան - Հայ քանդակագործ | Պաշտոնական կայք "
        />
        <meta
          name="title"
          content="Սարգիս Բաբայան - Հայ քանդակագործ | Պաշտոնական կայք "
        />
      </Helmet>
     <ContactUsBlock />
    </div>
  )
}

export default Contacts


