import React, { useState } from 'react';

const TabPanel = ({ categories, sortWorks, id }) => {
  const [activeTab, setActiveTab] = useState(id ?? null);

  const handleTabClick = (id) => {
    setActiveTab(id);
    sortWorks(id);
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between mt-4 pb-4 border-b">
      <p onClick={() => handleTabClick(0)} className="text-darkBg dark:text-lightBg text-4xl lg:text-5xl cursor-pointer">Works</p>
      <div className="flex items-center gap-x-3 md:gap-x-4 lg:gap-x-8 mt-6 lg:mt-0  overflow-x-auto">
        {categories.map((item, index) => (
          <div
            onClick={() => handleTabClick(item.id)}
            className={`cursor-pointer pb-1 ${
              activeTab === item.id ? 'border-b-2 border-darkBg dark:border-lightBg' : ''
            }`}
            key={index}
          >
            <p className="text-darkBg dark:text-lightBg font-custom tracking-wide text-nowrap">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabPanel;
