import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { baseApiUrl, fetchDataPaginationBase, getDynamicData } from "../service/base-url";
import TabPanel from "../components/TabPanel";
import ShowProducts from "../components/ShowProducts";
import axios from "axios";
import toast from "react-hot-toast";
import Arrow from "../images/arrow-right.svg";

const Works  = () => {
  const location = useLocation();
  const [categories, setCategories] = useState([])
  const [works, setWorks] = useState([])
  const [links, setLinks] = useState(null)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const scrollYRef = useRef(0);

  useEffect(() => {
    if (location.state) {
      sortWorks(location.state.item.id)
    }
    else {
      getDynamicData('works', setWorks, setLinks)
    }
  }, []);


  useEffect(() => {
    getDynamicData('work_categories', setCategories, null)
  }, [])

  const sortWorks = async (category_id) => {
    try {
      const url = category_id === 0
        ? `${baseApiUrl}/works`
        : `${baseApiUrl}/works?category_id=${category_id}`;

      const response = await axios.get(url, {
        headers: { 'Content-Type': 'application/json' },
      });

      const dataRes = response.data;
      if (dataRes) {
        setWorks(dataRes.data);
        setLinks(dataRes.links);
      }
    } catch (error) {
      toast.error('Please try again.');
      console.error('Error:', error);
    }
  };

  const fetchDataPagination = async (page) => {
    await fetchDataPaginationBase(
      page,
      (newData, newLinks) => {
        setWorks((prevData) => [...prevData, ...newData])
        setLinks(newLinks)
      },
      setLinks,
      null,
      'works'
    )
  }

  const handleLoadMore = async () => {
    if (isLoadingMore) return;
    setIsLoadingMore(true);

    scrollYRef.current = window.scrollY;

    const nextPage = currentPage + 1;
    await fetchDataPagination(nextPage);
    setCurrentPage(nextPage);

    setTimeout(() => {
      window.scrollTo(0, scrollYRef.current);
    }, 0);

    setIsLoadingMore(false);
  };

  return (
    <div className="px-5 xl:px-20">
      <TabPanel id={location?.state?.item.id} sortWorks={sortWorks} categories={categories} />
      <ShowProducts works={works} />
      {links?.next_page_url && (
        <div
          onClick={handleLoadMore}
          className="flex justify-center items-center py-14 cursor-pointer"
        >
          <button className='text-darkBg dark:text-lightBg py-2 px-4 my-2 rounded text-xl'>
            {isLoadingMore ? 'Loading...' : 'VIEW MORE'}
          </button>
          <img alt="png" src={Arrow} />

        </div>
      )}
    </div>
  )
}

export default Works
