import GoTo from "../GoTo";
import { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "../../utils";

const BlockThree = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="my-[64px]">
      <p className="text-4xl md:text-6xl text-center font-custom text-[#FFFFFF] text-darkBg dark:text-lightBg">WORKS</p>
      <div className="grid grid-cols-1 lg:grid-cols-2 mt-10 md:mt-28">
        <div className="px-5 md:px-0 flex justify-center items-start md:items-center flex-col space-y-6 md:space-y-6">
          {data && data.map((item, index) => (
            <div
              key={index}
              className={`tp-story__single fade-top ${index === activeIndex ? "active" : ""}`}
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <Link state={{item}} className={classNames('text-3xl md:text-8xl',index === activeIndex ? 'text-[#E1C17B] font-bold' : 'text-bordered',)} to={`/works`}>{item.category}</Link>
            </div>
          ))}
        </div>
        <div className="hidden md:flex justify-center items-center mt-10 md:mt-0">
          {data && data[activeIndex]?.image && (
            <img
              src={data[activeIndex].image}
              alt={data[activeIndex].title}
              className="tp-story-thumb thumb-active min-h-[530px] max-h-[530px] max-w-[520px]"
            />
          )}
        </div>
      </div>
      <GoTo title="FIND OUT MORE" to="/works" />
    </div>
  );
}

export default BlockThree;
