import { Link } from "react-router-dom";

const ExhibitionComponent = ({ gallery }) =>{
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-10 mt-10 space-y-3 md:space-y-0">
      {gallery && gallery.map((image, index) => {
        return (
          <Link key={index} to={`/exhibition/${image.id}`}>
          <div key={index}>
            <img className="w-full object-contain md:object-cover max-h-[428px] min-h-[280px] md:min-h-[428px]" src={image.image} alt="png" />
            <p className="text-darkBg dark:text-lightBg font-custom mt-5 text-2xl">{image.name}</p>
            <p className="text-darkBg dark:text-lightBg font-custom mt-5 text-xl">{image.date}</p>
          </div>
          </Link>
        )
      })}
    </div>
  )
}

export default ExhibitionComponent;


