import classNames from "../../utils";

const BlockFore = ({ biography }) => {
  return (
    <div className="mt-28 flex justify-between px-5 md:px-20">
        <div className="flex flex-col">
          <h1 className="text-subTitle text-2xl text-center md:text-start md:text-4xl font-bold font-custom">AWARDS AND DIPLOMAS</h1>
          <p
            className={classNames(
              'myHtml text-xl text-darkBg dark:text-lightBg mt-6 md:mt-12 leading-10 font-customFamily font-normal'
            )}
            dangerouslySetInnerHTML={{ __html: biography || '' }}
          />
        </div>
    </div>
  )
}

export default BlockFore